import axios from "axios"
import { PrizesListResponse, SpinPrize } from "./roulette.dto"
import { initDataMock } from "./common"

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

export const getPrizesList = async () => {
  const { data } = await axios.get<PrizesListResponse>(
    `${API_BASE_URL}/api/v1/widget/fortune/prizes`,
    { headers: { 'x-api-key': (window as any).Telegram?.WebApp?.initData || initDataMock } }
  )
  return data
};

export const makeSpin = async (userId: string) => {
  const { data } = await axios.post<SpinPrize>(
    `${API_BASE_URL}/api/v1/widget/fortune/make_spin?user_id=${userId}`,
    undefined,
    { headers: { 'x-api-key': (window as any).Telegram?.WebApp?.initData || initDataMock } }
  )
  return data
};

export const confirmPrize = async (prizeId: string, userId: string, email: string) => {
  const { data } = await axios.post<{ is_success: boolean }>(
    `${API_BASE_URL}/api/v1/widget/fortune/${prizeId}/confirm_prize?user_id=${userId}`,
    {email},
    { headers: { 'x-api-key': (window as any).Telegram?.WebApp?.initData || initDataMock } }
  )
  return data
};