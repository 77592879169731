import cn from 'classnames';

import styles from './roulette.module.scss';
import { FC, useRef } from 'react';
import { convertReward } from './roulette.hooks';
import { CloseIcon } from '../../icons';

interface QuestionModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const QuestionModal: FC<QuestionModalProps> = ({ isOpen, setIsOpen }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const handleClose = (e: React.MouseEvent<HTMLElement>) => {
    if (contentRef.current) {
      if (!contentRef.current.contains(e.target as Node)) {
        setIsOpen(false)
      }
    }
  };
  return (
    <div className={cn(styles.modal, styles.questionModal, {[styles.modalOpen]: isOpen})}>
      <div className={styles.modalInner} onClick={handleClose}>
        <div className={styles.modalContent} ref={contentRef}>
          <button className={styles.closeBtn} onClick={() => setIsOpen(false)}>
            <CloseIcon className={styles.closeIcon} />
          </button>
          <div className={styles.modalText}>
            Complete tasks and invite friends to earn free spins and even more rewards.
          </div>
        </div>
      </div>
    </div>
  )
}

interface EmailModalProps {
  email: string;
  setEmail: (email: string) => void;
  handleConfirmPrize: () => void;
  reward: string;
}

export const EmailModal: FC<EmailModalProps> = ({ email, setEmail, handleConfirmPrize, reward }) => {
  return (
    <div className={styles.modal}>
      <div className={styles.modalInner}>
        <div className={styles.modalContent}>
        <div className={styles.rewardInfo}>
        You win
        <div className={styles.rewardValue}>
            <img src='/pari.svg'alt='logo' className={styles.rewardLogo} />
            {convertReward(reward)}
        </div>
      </div>
          <div className={styles.modalText}>
            Enter your email to receive your prize by Pari
          </div>
          <input className={styles.input} placeholder='email' value={email} onChange={(e) => setEmail(e.target.value)} />
          <button 
            className={cn(styles.spinButton, styles.claimButton)} 
            onClick={handleConfirmPrize} 
            disabled={!email}
          >
            Get reward
          </button>
        </div>
      </div>
    </div>
  )
}