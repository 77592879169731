import { FC } from 'react';
import { DailyReward } from '../../../../../api/daily.dto';
import { CheckIcon } from '../../../../icons';
import styles from './dailyModal.module.scss';
import cn from 'classnames';

interface Props {
  dailyRewardInfo: DailyReward | null;
  handleClaimDailyReward: () => Promise<void>;
}


export const DailyModal: FC<Props> = ({ dailyRewardInfo, handleClaimDailyReward }) => {
  if (!dailyRewardInfo) {
    return null
  }
  return (
    <div className={styles.wrap}>
      <div className={styles.inner}>
        <div className={styles.content}>
          <div className={styles.title}>daily reward</div>
          <div className={styles.daysList}>
            {
              dailyRewardInfo.days.map((day) => {
                return (
                  <div key={day.day} className={cn(
                    styles.dayCard, 
                    styles[`dayCard${day.day}`], 
                    {[styles.dayCardCompleted]: day.is_completed}, 
                    {[styles.dayCardCurrent]: day.day === dailyRewardInfo.day_passed + 1},
                    {[styles.dayCardBlocked]: day.day > dailyRewardInfo.day_passed + 1}
                  )}>
                    <span className={styles.dayTitle}>Day {day.day}</span>
                    <span className={styles.dayReward}>
              
                      {
                        day.is_completed ? 
                        <CheckIcon className={styles.checkIcon} /> 
                        : <span>
                          <img src='/logo.svg' alt='logo' className={styles.coinIcon} />
                          {Number(day.reward).toFixed(0)}
                          </span>
                      }
                    </span>
                  </div>
                )
              })
            }
          </div>
          {dailyRewardInfo.can_claim && <button className={styles.btn} onClick={handleClaimDailyReward}>Get reward</button>}
        </div>
      </div>
    </div>
  )
}