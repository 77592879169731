import axios from "axios";
import { getApiKey } from "./common";
import { Task, SpecialTask } from "./tasks.dto";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

export const getUserTasks = async (userId: string) => {
  const apiKey = getApiKey()
  const { data } = await axios.get<{ count: number, results: Task[] }>(
    `${API_BASE_URL}/api/v1/widget/users/${userId}/tasks?offset=0&limit=20&is_today=false`, 
    { headers: { 'x-api-key': (window as any).Telegram?.WebApp?.initData } }
  );
  return data
}

export const completeUserTask = async (userId: string, taskId: string) => {
  const apiKey = getApiKey()
  const { data } = await axios.post<Task>(
    `${API_BASE_URL}/api/v1/widget/users/${userId}/tasks/${taskId}/completed`,
    undefined,
    { headers: { 'x-api-key': (window as any).Telegram?.WebApp?.initData } }
  )
  return data
}

export const getSpecialReferralTask = async (userId: string) => {
  const apiKey = getApiKey()
  const { data } = await axios.get<{ count: number, results: SpecialTask[] }>(
    `${API_BASE_URL}/api/v1/widget/users/${userId}/special_referral_tasks`,
    { headers: { 'x-api-key': (window as any).Telegram?.WebApp?.initData } }
  )
  return data
}
