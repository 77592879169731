import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28 28"
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M4.084 9.333a6.417 6.417 0 1 1 10.028 5.305 10.506 10.506 0 0 1 6.884 9.543.876.876 0 0 1-1.75.053 8.75 8.75 0 0 0-17.492 0 .875.875 0 0 1-1.75-.051 10.506 10.506 0 0 1 6.884-9.545 6.409 6.409 0 0 1-2.804-5.305ZM10.5 4.667a4.667 4.667 0 1 0 0 9.333 4.667 4.667 0 0 0 0-9.333Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M20.172 9.333c-.173 0-.34.011-.506.035a.875.875 0 1 1-.248-1.732 5.285 5.285 0 0 1 3.944 9.447 7.806 7.806 0 0 1 4.615 7.125.875.875 0 0 1-1.75 0 6.059 6.059 0 0 0-4.312-5.8l-.623-.188v-1.955l.478-.244a3.535 3.535 0 0 0-1.598-6.688Z"
    />
  </svg>
)
export { SvgComponent as FrensIcon }
