import { useCallback, useEffect, useState } from "react"
import { getTelegramId, getUserByTelegramId } from "../../../api/common"
import { transferToPari } from "../../../api/user"
import { User } from "../../../api/users.dto"
import { AxiosError } from "axios"


export const usePari = () => {
  const [user, setUser] = useState<User | null>(null)
  const [formValues, setFormValues] = useState({ email: '', amount: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  const getUser = useCallback(async () => {
    const userResponse = await getUserByTelegramId(getTelegramId())
    setUser(userResponse);
  }, [])


  const handleSubmit = useCallback(async (event: React.FormEvent) => {
    event.preventDefault();
    if (!user?.id) return;
    try {
      setIsLoading(true);
      setError(null);
      await transferToPari(user.id, formValues);
      setFormValues({ email: '', amount: '' });
      setSuccess(true);
    } catch (error) {
      setError(((error as AxiosError).response?.data as { detail: string })?.detail ?? 'Unknown error')
    } finally {
      setIsLoading(false);
    }
  }, [formValues, user])

  useEffect(() => {
    getUser()
  }, [getUser])

  return { handleSubmit, formValues, setFormValues, isLoading, error, success }
}