import React from "react";

export const PariIcon = ({ ...props }) => (
  <svg viewBox="0 0 108 108" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M35.9986 18.0334C35.9986 27.9691 44.0619 36.0322 53.9979 36.0322H89.9986C85.2254 36.0305 80.6482 34.1336 77.2731 30.7586C73.8979 27.3835 72.001 22.8064 71.9993 18.0334M71.9669 17.9988V0H35.9683V17.9988H71.9669ZM72.0015 90.0265C72.0015 80.0929 63.936 72.0298 54 72.0298H18.0015C27.9375 72.0298 36.0007 80.0929 36.0007 90.0286"
      fill="#038FFF" />
    <path d="M36.0326 90.0012V108H72.0311V90.0012H36.0326Z" fill="#038FFF" />
    <path
      d="M89.9986 36.0322C85.2254 36.0339 80.6504 37.9301 77.2752 41.3052C73.9001 44.6802 72.0032 49.2573 72.0014 54.0304L72.0015 90.0265C72.0015 80.0907 80.0647 72.027 90.0007 72.027M90.0007 71.9644H108V35.9667H90.0007V71.9644ZM18.0015 72.0298C27.9375 72.0298 36.0007 63.9661 36.0007 54.0304L35.9986 18.0334C35.9986 27.9691 27.9374 36.0337 18.0014 36.0337M17.9993 36.0315H0V72.0292L18.0015 72.0298L17.9993 36.0315Z"
      fill="#038FFF" />
  </svg>
);