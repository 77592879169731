import { FC } from 'react'
import { UserReferral } from '../../../api/users.dto'
import styles from './frens.module.scss'
import cn from 'classnames'
import ReactPaginate from 'react-paginate';
import { FRENS_LIMIT } from './frens.hooks'

type FrensGroupProps = {
  frens: UserReferral[],
  level: number,
  total: number,
  offset: number,
  setOffset: (newValue: number) => void
}

export const FrensGroup: FC<FrensGroupProps> = ({ frens, level, total, offset, setOffset }) => {
  if (!frens.length) {
    return null
  }

  const updatePagination = ({ selected }: { selected: number }) => {
    setOffset(selected * FRENS_LIMIT)
  }

  return <>
    <h1 className={styles.levelTitle}>Level {level} (total: {total})</h1>
    {frens.map((fren) => (
      <div className={cn(styles.card, styles.friendCard)} key={fren.username}>
        <span className={styles.friendName}>{fren.username || 'Unknown'}</span>
        <span>{fren.balance} CB</span>
      </div>
    ))
    }
    {
      total > FRENS_LIMIT && <ReactPaginate
        className={styles.paginateWrapper}
        breakLabel="..."
        nextLabel="next >"
        onPageChange={updatePagination}
        pageRangeDisplayed={5}
        pageCount={total / FRENS_LIMIT}
        previousLabel="< prev"
        renderOnZeroPageCount={null}
        activeLinkClassName={styles.activePaginateItem}
      />
    }
  </>
}