export enum PrizeType {
  CB = 'CB',
  TO_PARI = 'TO_PARI',
  TO_LOTO = 'TO_LOTO',
}

export type Prize = {
  id: string;
  type: PrizeType;
  reward: string;
}

export type SpinPrize = {
  id: string;
  prize: Prize;
  can_confirm: boolean;
}

export type PrizesListResponse = {
  count: number;
  results: Prize[];
}