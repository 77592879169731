import { FC } from 'react';
import cn from 'classnames';
import styles from './menu.module.scss';
import { ScreenTypes } from '../../../types';
import { FrensIcon, HomeIcon, PariIcon, RouletteIcon, TasksIcon } from '../../icons';
import { RocketIcon } from '../../icons/rocket';

interface Props {
  currentScreen: ScreenTypes,
  setCurrentScreen: (screen: ScreenTypes) => void,
}

const menuButtons = [
  {
    name: ScreenTypes.HOME,
    icon: HomeIcon,
  },
  {
    name: ScreenTypes.TASKS,
    icon: TasksIcon,
  },
  {
    name: ScreenTypes.BOOSTS,
    icon: RocketIcon,
  },
  {
    name: ScreenTypes.FRENS,
    icon: FrensIcon,
  },
  {
    name: ScreenTypes.PARI,
    icon: PariIcon,
  },
  {
    name: ScreenTypes.SPINNER,
    icon: RouletteIcon,
  }
];

const Menu: FC<Props> = ({ currentScreen, setCurrentScreen }) => {
  const handleChangeScreen = (screen: ScreenTypes) => () => setCurrentScreen(screen);

  return (
    <nav className={styles.wrap}>
      <ul className={styles.list}>
        {
          menuButtons.map((item) => {
            const isActive = item.name === currentScreen;
            return (
              <li className={styles.item} key={item.name}>
                <button
                  className={cn(styles.button, {
                    [styles.buttonActive]: isActive
                  })}
                  onClick={handleChangeScreen(item.name)}
                >
                  <item.icon className={styles.icon} />
                  {item.name}
                </button>
              </li>
            )
          })
        }
      </ul>
    </nav>
  )
}

export default Menu;
