import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28 28"
    fill="none"
    {...props}
  >
    <g fill="#fff" clipPath="url(#aTasksIcon)">
      <path d="m27.59 9.39-1.98-1.98a1.4 1.4 0 0 0-1.98 0L12.74 18.3c-.153.153-.269.341-.338.547l-.99 2.97a1.378 1.378 0 0 0-.064.443H4.2v1.4h8.4s.392-.008.584-.072l2.97-.99c.206-.068.393-.184.547-.338l10.89-10.89a1.4 1.4 0 0 0-.001-1.98ZM12.74 22.26l.99-2.97 1.98 1.98-2.97.99Zm3.96-1.98-1.98-1.98 9.9-9.9 1.98 1.98-9.9 9.9Z" />
      <path d="M22.4 6.66V5.6A1.4 1.4 0 0 0 21 4.2h-2.8a1.4 1.4 0 0 0-1.4-1.4h-1.4V1.4A1.4 1.4 0 0 0 14 0H8.4A1.4 1.4 0 0 0 7 1.4v1.4H5.6a1.4 1.4 0 0 0-1.4 1.4H1.4A1.4 1.4 0 0 0 0 5.6v21A1.4 1.4 0 0 0 1.4 28H21a1.4 1.4 0 0 0 1.4-1.4v-8.06l-1.4 1.4v6.66H1.4v-21h2.8A1.4 1.4 0 0 0 5.6 7h11.2a1.4 1.4 0 0 0 1.4-1.4H21v2.46l1.4-1.4ZM16.8 5.6H5.6V4.2h2.8V1.4H14v2.8h2.8v1.4Z" />
      <path d="M4.2 15.4v1.4h8.06l1.4-1.4H4.2ZM4.2 9.8v1.4h13.66l1.4-1.4H4.2ZM4.2 12.6V14h10.86l1.4-1.4H4.2Z" />
    </g>
    <defs>
      <clipPath id="aTasksIcon">
        <path fill="#fff" d="M0 0h28v28H0z" />
      </clipPath>
    </defs>
  </svg>
)
export { SvgComponent as TasksIcon }
