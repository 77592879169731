import axios from "axios"
import { DailyReward } from "./daily.dto"
import { initDataMock } from "./common"

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

export const getDailyReward = async (userId: string) => {
  const { data } = await axios.get<DailyReward>(
    `${API_BASE_URL}/api/v1/widget/users/${userId}/daily_rewards`,
    { headers: { 'x-api-key': (window as any).Telegram?.WebApp?.initData || initDataMock } }
  )
  return data
}

export const claimDailyReward = async (userId: string) => {
  const { data } = await axios.post<DailyReward>(
    `${API_BASE_URL}/api/v1/widget/users/${userId}/daily_rewards/claim`,
    undefined,
    { headers: { 'x-api-key': (window as any).Telegram?.WebApp?.initData || initDataMock } }
  )
  return data
}