import { useEffect, useState } from "react"
import { UserReferral } from "../../../api/users.dto"
import { getReferralsByLine, getTotalReferrals } from "../../../api/user"
import { getReferralLink } from "../../../utils/getReferralLink"
import { getTelegramId, getUserByTelegramId } from "../../../api/common"

export const FRENS_LIMIT = 10

export const useFrensPage = () => {
  const [referralLink, setReferralLink] = useState('')
  const [totalFrens, setTotalFrens] = useState(0)
  
  const [level1Friends, setLevel1Friends] = useState<UserReferral[]>([])
  const [level2Friends, setLevel2Friends] = useState<UserReferral[]>([])
  const [level3Friends, setLevel3Friends] = useState<UserReferral[]>([])

  const [level1Offset, setLevel1Offset] = useState(0)
  const [level2Offset, setLevel2Offset] = useState(0)
  const [level3Offset, setLevel3Offset] = useState(0)

  const [level1Total, setLevel1Total] = useState(0)
  const [level2Total, setLevel2Total] = useState(0)
  const [level3Total, setLevel3Total] = useState(0)

  const refreshLine = async (userId: string, line: number, offset: number, limit: number, setFrens: (val: UserReferral[]) => void, setTotal: (val: number) => void) => {
    const { count, results } = await getReferralsByLine(userId, line, offset, limit)
    setFrens(results)
    setTotal(count)
  }

  useEffect(() => {
    const init = async () => {
      const user = await getUserByTelegramId(getTelegramId())
      refreshLine(user.id, 1, level1Offset, FRENS_LIMIT, setLevel1Friends, setLevel1Total)  
    }
    void init()
  }, [level1Offset])

  useEffect(() => {
    const init = async () => {
      const user = await getUserByTelegramId(getTelegramId())
      refreshLine(user.id, 2, level2Offset, FRENS_LIMIT, setLevel2Friends, setLevel2Total)  
    }
    void init()
  }, [level2Offset])

  useEffect(() => {
    const init = async () => {
      const user = await getUserByTelegramId(getTelegramId())
      refreshLine(user.id, 3, level3Offset, FRENS_LIMIT, setLevel3Friends, setLevel3Total)  
    }
    void init()
  }, [level3Offset])

  const onUpdate = async () => {
    const user = await getUserByTelegramId(getTelegramId())
    const { count } = await getTotalReferrals(user.id)
    setTotalFrens(count)

    refreshLine(user.id, 1, level1Offset, FRENS_LIMIT, setLevel1Friends, setLevel1Total)
    refreshLine(user.id, 2, level2Offset, FRENS_LIMIT, setLevel2Friends, setLevel2Total)
    refreshLine(user.id, 3, level3Offset, FRENS_LIMIT, setLevel3Friends, setLevel3Total)

    setReferralLink(getReferralLink(getTelegramId()))
  }

  const onClickReferralButton = () => {
    void navigator.clipboard.writeText(referralLink)
    alert('Link copied to clipboard!')
  }

  useEffect(() => {
    void onUpdate()
  }, [])

  return { 
    onClickReferralButton, 
    totalFrens, 
    
    level1Friends, 
    level2Friends, 
    level3Friends, 
    
    level1Offset, 
    level2Offset, 
    level3Offset, 
    
    setLevel1Offset, 
    setLevel2Offset, 
    setLevel3Offset,

    level1Total,
    level2Total,
    level3Total,
   }
}