import React from "react";

import styles from './pari.module.scss'
import { usePari } from "./pari.hooks";

export const PariScreen: React.FC = () => {
  const { formValues, setFormValues, handleSubmit, isLoading, error, success } = usePari();

  return (
    <div className={styles.PariScreen}>
      <form className={styles.card} onSubmit={handleSubmit}>
        <h2 className={styles.title}>Transfer CB<br />to PariMedia</h2>
        <p>
          To transfer funds to your PariMedia account, please provide the email address you used when registering.
        </p>
        <input
          className={styles.input}
          placeholder="Email"
          name="email"
          onChange={(event) => {
            setFormValues({ ...formValues, email: event.target.value })
          }}
        />

        <p>
        Enter the amount of CB you want to transfer to the website <a href="https://pari.media" target="_blank" rel="noreferrer">pari.media</a>
        </p>
        <input
          className={styles.input}
          placeholder="Amount"
          type="number"
          name="amount"
          onChange={(event) => {
            setFormValues({ ...formValues, amount: event.target.value })
          }}
        />

        {error && <p className={styles.error}>{error}</p>}
        {success && <p className={styles.success}>You successfully transferred funds!</p>}

        <button className={styles.button} type="submit" disabled={isLoading}>
          Send{isLoading && '...'}
        </button>
      </form>
    </div>
  );
};