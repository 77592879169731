import { useEffect, useState } from "react"
import { getTelegramId, getUserByTelegramId } from "../../../api/common"
import { completeUserTask, getSpecialReferralTask, getUserTasks } from "../../../api/tasks"
import { SpecialTask, Task } from "../../../api/tasks.dto"
import { openInNewTab } from "../../../utils/openInNewTab"
import { updateTwitterProfile } from "../../../api/user"
import { User } from "../../../api/users.dto"

type TaskMetadata = Task & {
  isClicked: boolean;
}

export const useTasks = () => {
  const [permanentTasks, setPermanentTasks] = useState<TaskMetadata[]>([])
  const [dailyTasks, setDailyTasks] = useState<TaskMetadata[]>([])
  const [specialTasks, setSpecialTasks] = useState<SpecialTask[]>([])
  const [user, setUser] = useState<User | null>(null)

  const [count, setCount] = useState(0)
  
  const onUpdate = async () => {
    const user = await getUserByTelegramId(getTelegramId())
    setUser(user)
    const { results: newTasks } = await getUserTasks(user.id)
    const incompleteTasks = newTasks.filter(task => !task.is_completed)
    setPermanentTasks(incompleteTasks.filter(task => task.is_static).map(task => ({
      ...task, isClicked: false
    })))
    setDailyTasks(incompleteTasks.filter(task => !task.is_static).map(task => ({
      ...task, isClicked: false
    })))
    setCount(incompleteTasks.length)
    const specials = await getSpecialReferralTask(user.id)
    setSpecialTasks(specials.results)
  }

  const handleTaskClick = (taskId: string) => async () => {
    const task = [...permanentTasks, ...dailyTasks].find(t => t.id === taskId)
    if (!task) {
      return
    }
    if (task.is_static) {
      setPermanentTasks(permanentTasks.map(t => t.id === taskId ? { ...t, isClicked: true } : t))
    } else {
      setDailyTasks(dailyTasks.map(t => t.id === taskId ? { ...t, isClicked: true } : t))
    }
    openInNewTab(task.link_or_name)
  }

  const handleTaskConfirm = (taskId: string) => async () => {
    if (!user) {
      console.log('no user')
      alert('Something went wrong')
      return
    }
    try {
      await completeUserTask(user.id, taskId)
      onUpdate()
    } catch (e) {
      console.error(e)
      alert('The task condition was not met!')
    }
  }

  useEffect(() => {
    onUpdate()
  }, [])

  const confirmTwitter = async (twitterName: string) => {
    if (!user) {
      console.log('no user')
      alert('Something went wrong')
      return
    }
    try {
      await updateTwitterProfile(user.id, twitterName)
      onUpdate()
    } catch (e) {
      console.error(e)
      alert('Incorrect Twitter username')
    }
  }

  return { permanentTasks, dailyTasks, count, handleTaskClick, handleTaskConfirm, confirmTwitter, user, specialTasks }
}