import { useEffect, useState } from "react";
import { FarmingStates } from "../../../types";
import { User } from "../../../api/users.dto";
import { completeJob, createJob, getLatestJob } from "../../../api/jobs";
import { getTelegramId, getUserByTelegramId } from "../../../api/common";
import { getAccessToken } from "../../../api/user";
import { DailyReward } from "../../../api/daily.dto";
import { claimDailyReward, getDailyReward } from "../../../api/daily";

export const useHomeScreen = () => {
  const [farmingState, setFarmingState] = useState<FarmingStates>(FarmingStates.START);
  const [user, setUser] = useState<User | null>(null);
  const [startAt, setStartAt] = useState<Date | null>(null);
  const [endAt, setEndAt] = useState<Date | null>(null);
  const [jobAmount, setJobAmount] = useState(0)
  const [currentAmount, setCurrentAmount] = useState(0)
  const [timeToFinish, setTimeToFinish] = useState(0)
  const [progressPercent, setProgressPercent] = useState(0)
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [dailyRewardInfo, setDailyRewardInfo] = useState<DailyReward | null>(null);

  const convertUtcDateStringToLocalDate = (date: string) => {
    const utcDate = new Date(date)
    return new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60 * 1000)
  }

  useEffect(() => {
    const init = async () => {
      const userData = await refreshUser()
      
      try {
        const accessTokenResponse = await getAccessToken(userData.id);
        setAccessToken(accessTokenResponse);
        const existingGame = await getLatestJob(userData.id)
        if (existingGame) {
          if (existingGame.status === 'READY') {
            setFarmingState(FarmingStates.CLAIM)
            setJobAmount(existingGame.amount) 
          } else if (existingGame.status === 'PROCESSING') {
            setFarmingState(FarmingStates.FARMING);
            const endDate = convertUtcDateStringToLocalDate(existingGame.end_period)
            const startAt = new Date(endDate.getTime() - 8 * 60 * 60 * 1000)
            setStartAt(startAt)
            setEndAt(endDate)
            setJobAmount(existingGame.amount) 
          } else if (existingGame.status === 'COMPLETED') {
            // nothing 
          }
        }
      } catch (e) {
        console.error(e)
      }

      try {
        const dailyReward = await getDailyReward(userData.id)
        setDailyRewardInfo(dailyReward)
      } catch (e) {
        console.error(e)
      }
    }

    init().catch(console.error)
  }, [])
  
  useEffect(() => {
    if (!endAt || new Date() > endAt) {
      return
    }

    const intervalId = setInterval(() => {
      if (!endAt || !startAt) {
        return
      }
      const newProgressPercent = Math.min((Date.now() - startAt.getTime()) / (endAt.getTime() - startAt.getTime()) * 100, 100)
      setProgressPercent(Math.floor(newProgressPercent))
      const newCurrentAmount = jobAmount * newProgressPercent / 100
      setCurrentAmount(newCurrentAmount)
      const timeToEnd = Math.floor((endAt.getTime() - Date.now()) / 1000) 
      setTimeToFinish(timeToEnd)
    }, 1000)
  
    return () => clearInterval(intervalId)
  }, [endAt, startAt, jobAmount])

  const refreshUser = async () => {
    const telegramId = getTelegramId()
    const userData = await getUserByTelegramId(telegramId)
    setUser(userData)
    return userData
  }

  const handleStartFarming = async () => {
    const user = await getUserByTelegramId(getTelegramId())
    const game = await createJob(user.id)
    setFarmingState(FarmingStates.FARMING);    
    const endDate = convertUtcDateStringToLocalDate(game.end_period)
    const startAt = new Date(endDate.getTime() - 8 * 60 * 60 * 1000)
    setStartAt(startAt)
    setEndAt(endDate)
    setJobAmount(game.amount)
  }

  const handleClaimFarming = async () => {
    if (!user) {
      return
    }
    const latestJob = await getLatestJob(user.id)
    await completeJob(user.id, latestJob.id)
    await refreshUser()
    setFarmingState(FarmingStates.START)
  }

  const handleClaimDailyReward = async () => {
    if (!user) {
      return
    }

    try {
      const updatedDailyReward = await claimDailyReward(user.id)
      if (updatedDailyReward) {
        setDailyRewardInfo(updatedDailyReward)
      }
      await refreshUser()
    } catch (e) {
      console.error(e)
    }
  }

  return {
    accessToken,
    farmingState, 
    user, 
    handleStartFarming, 
    currentAmount, 
    jobAmount, 
    timeToFinish,
    progressPercent,
    handleClaimFarming,
    dailyRewardInfo,
    handleClaimDailyReward
  }
}