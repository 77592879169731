import { ChangeEvent, useState } from 'react';
import { useTasks } from './tasks.hooks';
import styles from './tasks.module.scss';
import cn from 'classnames';
import { getReferralLink } from '../../../utils/getReferralLink';
import { getTelegramId } from '../../../api/common';

export default function Tasks() {
  const { permanentTasks, dailyTasks, count, handleTaskClick, handleTaskConfirm, confirmTwitter, user, specialTasks } = useTasks()
  const [twitterName, setTwitterName] = useState('')
  const [isEditingTwitterName, setIsEditingTwitterName] = useState(false);

  const handleConfirmTwitter = () => {
    if (!twitterName) {
      return
    }
    setIsEditingTwitterName(false)
    confirmTwitter(twitterName)
  }

  const handleChangeTwitter = () => {
    setIsEditingTwitterName(true)    
  }

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setTwitterName(e.target.value || '')
  }

  const onClickReferralButton = () => {
    if (!user) {
      alert('Something went wrong')
      return
    }
    const referralLink = getReferralLink(getTelegramId());
    void navigator.clipboard.writeText(referralLink)
    alert('Link copied to clipboard!')
  }

  return (
    <div className={styles.wrapper}>
      {specialTasks.length > 0 && <div className={styles.inner}>
        <ul className={styles.list}>
          {
            specialTasks.map((task) => {
              return (
                <li className={styles.itemSpecial}>
                    {/* TODO данные и действия */}
                    <p className={cn(styles.itemText, styles.itemTextSpecial)}>{task.description}</p>
                    <div className={styles.item}>
                      <div>
                        <p className={styles.specialRemaning}>{task.extra.referral_start_jobs_count}&nbsp;/&nbsp;{task.extra.need_count}</p>
                        <p className={styles.itemReward}>+ {task.cost} CB</p>
                      </div>
                      {(task.extra.referral_start_jobs_count === task.extra.need_count) ? 
                      <button className={styles.startButton} onClick={handleTaskConfirm(task.id)}>Confirm</button>
                      : <button className={styles.startButton} onClick={onClickReferralButton}>Invite</button>
                    }
                    </div>
                </li>
              )
            })
          }
        </ul>
      </div>}
      {(user?.twitter_profile && !isEditingTwitterName) ? <div className={styles.inner}>
        <span className={styles.itemText}>
          Your Twitter (X) username
        </span>
        <div className={styles.item}>
          <span className={cn(styles.itemText, styles.itemTextLarge)}>
            {user?.twitter_profile}
          </span>
          <button className={styles.button} onClick={handleChangeTwitter}>Change</button>
        </div>
      </div> : <div className={styles.inner}>
        <span className={styles.itemText}>
          Add Twitter (X) username
        </span>
        <div className={styles.item}>
          <input className={styles.input} onChange={onChangeName}>
          </input>
          <button className={styles.button} onClick={handleConfirmTwitter}>Confirm</button>
        </div>
      </div>}
      <div className={styles.inner}>
        {/* TODO количество */}
        <h2 className={styles.title}>{count} task{count > 1 ? 's' : ''} available</h2>
        <p className={styles.text}>
          We'll reward you immediately with points after each task completion.
        </p>
        <div className={styles.listWrap}>
          {permanentTasks.length !== 0 &&
            <h2>Permanent tasks</h2>
          }
          {permanentTasks.length !== 0 &&
            <ul className={styles.list}>
              {permanentTasks.map((task, index) => (
                <li className={styles.item} key={task.id}>
                  <div className={styles.itemDescription}>
                    {/* TODO данные и действия */}
                    <p className={styles.itemText}>{task.name}</p>
                    <p className={styles.itemReward}>+ {task.cost} CB</p>
                  </div>
                  {task.isClicked
                    ? <button className={styles.startButton} onClick={handleTaskConfirm(task.id)}>Confirm</button>
                    : <button className={styles.startButton} onClick={handleTaskClick(task.id)}>Start</button>
                  }
                </li>
              ))}
            </ul>
          }
          {dailyTasks.length !== 0 &&
            <h2>Daily tasks</h2>
          }
          {dailyTasks.length !== 0 &&
            <ul className={styles.list}>
              {dailyTasks.map((task, index) => (
                <li className={styles.item} key={task.id}>
                  <div className={styles.itemDescription}>
                    {/* TODO данные и действия */}
                    <p className={styles.itemText}>{task.name}</p>
                    <p className={styles.itemReward}>+ {task.cost} CB</p>
                  </div>
                  {task.isClicked
                    ? <button className={styles.startButton} onClick={handleTaskConfirm(task.id)}>Confirm</button>
                    : <button className={styles.startButton} onClick={handleTaskClick(task.id)}>Start</button>
                  }
                </li>
              ))}
            </ul>
          }
        </div>
      </div>
    </div>
  )
}