import { useEffect, useState } from "react"
import { boostsContent } from "./boosts.content"
import { getTelegramId, getUserByTelegramId } from "../../../api/common"
import { updateTwitterProfile, upgradeUserSpeedSkill } from "../../../api/user"
import { User } from "../../../api/users.dto"

type Boost = {
  boostInfo: string;
  price: number;
  isBought: boolean;
}

export const useBoostsScreen = () => {
  const [boosts, setBoosts] = useState<Boost[]>([])
  const [user, setUser] = useState<User | null>(null)
  
  const handleBuyBoost = (boostPrice: number) => async () => {
    const boost = boostsContent.find(el => el.price === boostPrice)
    if (!boost) {
      return
    }
    const user = await getUserByTelegramId(getTelegramId())
    if (user.balance < boostPrice) {
      alert('Not enough balance!')
      return
    }
    await upgradeUserSpeedSkill(user.id)
    await refreshBoosts()
  }

  const refreshBoosts = async () => {
    const newUser = await getUserByTelegramId(getTelegramId())
    setUser(newUser)
    setBoosts(boostsContent.map((el, index) => ({ ...el, isBought: newUser.speed_level > index })))
  }

  useEffect(() => {
    setBoosts(boostsContent.map(el => ({ ...el, isBought: false })))
    void refreshBoosts()
  }, [])

  const confirmTwitter = async (twitterName: string) => {
    if (!user) {
      return
    }
    try {
      await updateTwitterProfile(user.id, twitterName)
    } catch (e) {
      console.error(e)
      alert('Incorrect Twitter username')
    }
    refreshBoosts()
  }

  return { boosts, handleBuyBoost, user, confirmTwitter }
}