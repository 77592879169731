export const boostsContent = [
  {
    boostInfo: "+10%",
    price: 400
  },
  {
    boostInfo: "+20%",
    price: 800
  },
  {
    boostInfo: "+40%",
    price: 2400
  },
  {
    boostInfo: "+80%",
    price: 6800
  },
  {
    boostInfo: "+160%",
    price: 15200
  }
]