import axios from "axios"
import { getApiKey } from "./common"
import { Job } from "./jobs.dto"

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

export const createJob = async (userId: string) => {
  const apiKey = getApiKey()
  const { data } = await axios.post<Job>(
    `${API_BASE_URL}/api/v1/widget/users/${userId}/jobs`,
    undefined,
    { headers: { 'x-api-key': (window as any).Telegram?.WebApp?.initData } }
  )
  return data
}

export const getJobsList = async (userId: string) => {
  const apiKey = getApiKey()
  const { data } = await axios.get<{ count: number, jobs: Job[] }>(
    `${API_BASE_URL}/api/v1/widget/users/${userId}/jobs?offset=0&limit=20`,
    { headers: { 'x-api-key': (window as any).Telegram?.WebApp?.initData } }
  )
  return data
}

export const completeJob = async (userId: string, jobId: string) => {
  const apiKey = getApiKey()
  const { data } = await axios.post<Job>(
    `${API_BASE_URL}/api/v1/widget/users/${userId}/jobs/${jobId}/complete`,
    undefined, 
    { headers: { 'x-api-key': (window as any).Telegram?.WebApp?.initData } }
  )
  return data
}

export const getLatestJob = async (userId: string) => {
  const apiKey = getApiKey()
  const { data } = await axios.get<Job>(
    `${API_BASE_URL}/api/v1/widget/users/${userId}/latest_job`,
    { headers: { 'x-api-key': (window as any).Telegram?.WebApp?.initData } }
  )
  return data
}

export const calcJobAward = async (userId: string) => {
  const apiKey = getApiKey()
  const { data } = await axios.get<{ amount: number }>(
    `${API_BASE_URL}/api/v1/widget/users/${userId}/calc_award`,
    { headers: { 'x-api-key': (window as any).Telegram?.WebApp?.initData } }
  )
  return data
}