export enum ScreenTypes {
  HOME = 'Home',
  TASKS = 'Tasks',
  BOOSTS = 'Boosts',
  FRENS = 'Friends',
  PARI = 'PARI',
  SPINNER = 'Spinner',
}

export enum FarmingStates {
  FARMING = 'Farming',
  CLAIM = 'claim',
  START = 'start',
}