import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';
import { ScreenTypes } from './types';
import Menu from './components/layout/menu/menu';
import Home from './components/screens/home/home';
import Frens from './components/screens/frens/frens';
import Tasks from './components/screens/tasks/tasks';
import { Boosts } from './components/screens/boosts/boosts';
import { PariScreen } from './components/screens/pari';
import { Roulette } from './components/screens/roulette/roulette';

function App() {
  const [currentScreen, setCurrentScreen] = React.useState<ScreenTypes>(ScreenTypes.HOME);
  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <div className={cn(styles.content, {[styles.contentHome]: false})}>
          {currentScreen === ScreenTypes.HOME && <Home />}
          {currentScreen === ScreenTypes.FRENS && <Frens />}
          {currentScreen === ScreenTypes.TASKS && <Tasks />}
          {currentScreen === ScreenTypes.BOOSTS && <Boosts />}
          {currentScreen === ScreenTypes.PARI && <PariScreen />}
          {currentScreen === ScreenTypes.SPINNER && <Roulette />}
        </div>
        <Menu currentScreen={currentScreen} setCurrentScreen={setCurrentScreen} />
      </div>
    </div>
  );
}

export default App;
