import axios from "axios"
import { User } from "./users.dto"

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

export const getApiKey = () => {
  return '0b2d0f67fa4649a4e006d1c2cosBhrqb4Co4C9UPVumbLebVkzWJCK0Ni2KuU8A7X'
}

// 315974254
// export const initDataMock = 'query_id=AAFuYtUSAAAAAG5i1RL5obFT&user=%7B%22id%22%3A315974254%2C%22first_name%22%3A%22Ilya%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22damnnotagain%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1720458832&hash=ca5e69fe898c04eb4174cba81cd1fd2d5523ac669de058bf72007cbb253f669d'
export const initDataMock = ''

export const getUserByTelegramId = async (telegramId: number) => {
  const apiKey = getApiKey()
  const { data } = await axios.get<User>(
    `${API_BASE_URL}/api/v1/widget/users/telegram/${telegramId}`,
    { headers: { 'x-api-key': (window as any).Telegram?.WebApp?.initData || initDataMock } }
  )
  return data
}

export const getTelegramId = (): number => {
  const tg = (window as any).Telegram?.WebApp;
  const userId = tg?.initDataUnsafe?.user?.id;
  if (!userId) {
    alert('App can be userd from Telegram only!')
    return getTelegramId()
  }
  return userId as number
}

export const getTelegramPhotoUrl = () => {
  const tg = (window as any).Telegram?.WebApp;
  const photoUrl = tg?.initDataUnsafe?.user?.photo_url;
  return photoUrl as string | undefined
}