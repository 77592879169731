import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={800}
    height={800}
    viewBox="0 0 36 36"
    {...props}
  >
    <title>{"roulette"}</title>
    <path
      fill="#fff"
      fillRule="nonzero"
      d="M18 0c9.941 0 18 8.059 18 18s-8.059 18-18 18S0 27.941 0 18 8.059 0 18 0ZM9.294 27.655l-2.123 2.124a15.939 15.939 0 0 0 9.83 4.19v-3.007a12.949 12.949 0 0 1-7.707-3.307Zm9.707 3.307v3.007a15.944 15.944 0 0 0 10.121-4.467.995.995 0 0 1-.172-.138l-1.967-1.966A12.954 12.954 0 0 1 19 30.962ZM18 7C11.925 7 7 11.925 7 18s4.925 11 11 11 11-4.925 11-11S24.075 7 18 7ZM5.038 19H2.03a15.93 15.93 0 0 0 3.755 9.336l2.13-2.13A12.94 12.94 0 0 1 5.038 19Zm28.931 0h-3.007a12.935 12.935 0 0 1-2.642 6.906l2.044 2.044c.031.031.06.064.087.099A15.922 15.922 0 0 0 33.969 19Zm-17.383-6.657a3.001 3.001 0 0 1 .587 3.414l.828.828.827-.827a3.001 3.001 0 1 1 1.415 1.415l-.828.826.828.828a3.001 3.001 0 1 1-1.415 1.415L18 19.414l-.828.829a3.001 3.001 0 1 1-1.415-1.415l.828-.829-.828-.827a3.001 3.001 0 1 1 .828-4.829Zm-2.829 8.485a1 1 0 1 0 1.415 1.415 1 1 0 0 0-1.415-1.415Zm7.071 0a1 1 0 1 0 1.415 1.415 1 1 0 0 0-1.415-1.415Zm9.204-13.375L27.905 9.58A12.943 12.943 0 0 1 30.962 17h3.007a15.934 15.934 0 0 0-3.937-9.547ZM2.03 17h3.007a12.94 12.94 0 0 1 2.878-7.206l-2.13-2.131A15.93 15.93 0 0 0 2.031 17Zm11.726-3.244a1 1 0 1 0 1.415 1.415 1 1 0 0 0-1.415-1.415Zm7.071 0a1 1 0 1 0 1.415 1.415 1 1 0 0 0-1.415-1.415ZM7.171 6.221l2.124 2.123A12.949 12.949 0 0 1 17 5.038V2.03a15.939 15.939 0 0 0-9.829 4.19Zm11.83-4.19v3.007a12.945 12.945 0 0 1 7.496 3.123l2.126-2.126A15.935 15.935 0 0 0 19 2.031Z"
    />
  </svg>
)
export { SvgComponent as RouletteIcon }
