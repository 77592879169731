import { FC, useState } from 'react';
import styles from './roulette.module.scss';
import cn from 'classnames';
import { convertReward, useRouletteScreen } from './roulette.hooks';
import { Wheel } from 'react-custom-roulette';
import { PrizeType } from '../../../api/roulette.dto';
import { QuestionIcon } from '../../icons';
import { EmailModal, QuestionModal } from './modals';

export const Roulette: FC = () => {
  const { 
    user, 
    prizes, 
    canConfirm, 
    winnedPrizeIdx, 
    handleSpin, 
    handleConfirmPrize, 
    mustSpin, 
    setMustSpin, 
    email, 
    setEmail, 
    winInitial 
  } = useRouletteScreen();
  const [isQuestionModalOpen, setIsQuestionModalOpen] = useState(false);

  const handleOpenQuestionModal = () => {
    setIsQuestionModalOpen(true);
  };
  
  if (prizes?.length === 0 || !user) {
    return null;
  }

  const spinningFinished = !mustSpin && winInitial;
  
  return (
    <div className={styles.wrapper}>
      <QuestionModal isOpen={isQuestionModalOpen} setIsOpen={setIsQuestionModalOpen} />
      <div className={styles.header}>
        <div>
          <div className={styles.balanceTitle}>Balance:</div>
          <div className={styles.balance}>
            <img src='/logo.svg' alt='logo' className={styles.bitcoinIcon} />
            <span>{Number(user?.balance || 0).toFixed(3)}</span>
          </div>
          <div className={styles.balanceTitle}>
            Spins left: {user.number_of_fortune_spins}
          </div>
        </div>
        <button className={styles.questionBtn} onClick={handleOpenQuestionModal}>
          <span>Get more free spins</span>
          <QuestionIcon className={styles.questionIcon} />
        </button>
      </div>
      
      <div className={styles.rouletteWrap}>
        <div className={styles.roulette}>
          <Wheel
            mustStartSpinning={mustSpin}
            prizeNumber={winnedPrizeIdx}
            data={prizes}
            backgroundColors={['#B946E7', '#1A1A1A']}
            textColors={['#ffffff']}
            outerBorderWidth={0}
            fontFamily="Euclid"
            fontSize={24}
            spinDuration={2}
            onStopSpinning={() => {
              setMustSpin(false);
            }}
            
          />
        </div>
      </div>
      {spinningFinished && <div className={styles.rewardInfo}>
        Reward:
        <div className={styles.rewardValue}>
            <img src={
              winInitial.prize.type === PrizeType.CB ? '/logo.svg'
              : winInitial.prize.type === PrizeType.TO_PARI ? '/pari.svg'
              : winInitial.prize.type === PrizeType.TO_LOTO ? '/loto.svg'
              : '/logo.svg'
            } alt='logo' className={styles.rewardLogo} />
            {convertReward(winInitial.prize.reward)}
        </div>
      </div>}
      {
        (!canConfirm || !spinningFinished) && 
          <button 
          className={styles.spinButton} 
          onClick={handleSpin} 
          disabled={mustSpin || user.number_of_fortune_spins === 0}
          >
            Spin
          </button>
      }
      
      {
        canConfirm && 
        !mustSpin && 
        winInitial?.prize.type === PrizeType.TO_PARI && 
        <EmailModal email={email} setEmail={setEmail} handleConfirmPrize={handleConfirmPrize} reward={winInitial.prize.reward} />
      }
      {
        canConfirm && 
        !mustSpin && 
        winInitial?.prize.type !== PrizeType.TO_PARI &&
          <button 
            className={cn(styles.spinButton, styles.claimButton)} 
            onClick={handleConfirmPrize}
          >
            Get reward
          </button>}
    </div>
  )
}