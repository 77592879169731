import { ChangeEvent, FC, useState } from "react"
import styles from './boosts.module.scss'
import { useBoostsScreen } from "./boosts.hooks"
import cn from 'classnames'

export const Boosts: FC = () => {
  const { boosts, handleBuyBoost, user, confirmTwitter } = useBoostsScreen()
  const [twitterName, setTwitterName] = useState('')

  const handleConfirmTwitter = () => {
    if (!twitterName) {
      return
    }
    confirmTwitter(twitterName)
  }

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setTwitterName(e.target.value || '')
  }

  return <div className={styles.wrapper}>
    <div className={styles.inner}>
    { 
      boosts.map((boost, index) => 
        <div className={styles.item} key={boost.price}>
          <div className={styles.itemDescription}>
            <p className={styles.itemText}>Speed: {boost.boostInfo}</p>
            <p className={styles.itemReward}>Price: {boost.price} CB</p>
          </div>
          {boost.isBought 
            ? <button className={cn(styles.button, styles.buttonDisabled)} disabled>Bought</button> 
            : <button 
              className={cn(styles.button, {[styles.buttonDisabled]: user?.speed_level !== index })} 
              onClick={handleBuyBoost(boost.price)}
              disabled={user?.speed_level !== index} 
            >Buy</button>}
        </div>
      ) 
    }
    </div>
  </div>
}