import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28 28"
    fill="none"
    {...props}
  >
    <g clipPath="url(#aHomeIcon)">
      <path
        fill="#fff"
        d="M27.8 14.037 14.665.278a.873.873 0 0 0-1.263 0L.2 14.037a.875.875 0 1 0 1.263 1.212l1.176-1.226v13.093c0 .483.392.875.875.875h6.113c.482 0 .873-.39.875-.873l.027-8.74H17.5v8.738c0 .483.392.875.875.875h6.11a.875.875 0 0 0 .875-.875V14.018l1.175 1.232a.876.876 0 0 0 1.237.025.875.875 0 0 0 .026-1.236l.001-.002Zm-4.188-1.601V26.24h-4.36v-8.738a.875.875 0 0 0-.875-.875h-8.72a.875.875 0 0 0-.875.872l-.027 8.74H4.39V12.436a.873.873 0 0 0-.028-.21l9.673-10.08 9.61 10.07a.875.875 0 0 0-.032.22h-.001Z"
      />
    </g>
    <defs>
      <clipPath id="aHomeIcon">
        <path fill="#fff" d="M0 0h28v28H0z" />
      </clipPath>
    </defs>
  </svg>
)
export { SvgComponent as HomeIcon }
