import axios from "axios"
import { TransferToPariRequest, User, UserReferral } from "./users.dto"
import { getApiKey, initDataMock } from "./common"

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

export const upgradeUserSpeedSkill = async (userId: string) => {
  const apiKey = getApiKey()
  const { data } = await axios.post<User>(
    `${API_BASE_URL}/api/v1/widget/users/${userId}/upgrade_speed_skill`,
    undefined,
    { headers: { 'x-api-key': (window as any).Telegram?.WebApp?.initData } }
  )
  return data
}

export const getAccessToken = async (userId: string): Promise<string> => {
  const apiKey = getApiKey();
  const { data } = await axios.get(
    `${API_BASE_URL}/api/v1/widget/users/${userId}/access_token`,
    { headers: { 'x-api-key': (window as any).Telegram?.WebApp?.initData || initDataMock } },
  )
  return data.access_token;
}

export const getUserReferralTree = async (userId: string) => {
  const apiKey = getApiKey()
  const { data } = await axios.get<{ level_1: UserReferral[], level_2: UserReferral[], level_3: UserReferral[] }>(
    `${API_BASE_URL}/api/v1/widget/users/${userId}/referral_tree`,
    { headers: { 'x-api-key': (window as any).Telegram?.WebApp?.initData } }
  )
  return data
}

export const updateTwitterProfile = async (userId: string, twitter_profile: string) => {
  const apiKey = getApiKey()
  const { data } = await axios.post<User>(
    `${API_BASE_URL}/api/v1/widget/users/${userId}/twitter`,
    { twitter_profile },
    { headers: { 'x-api-key': (window as any).Telegram?.WebApp?.initData } }
  )
  return data
}

export const getTotalReferrals = async (userId: string) => {
  const apiKey = getApiKey()
  const { data } = await axios.get<{ count: number }>(
    `${API_BASE_URL}/api/v1/widget/users/${userId}/total_referral_count`,
    { headers: { 'x-api-key': (window as any).Telegram?.WebApp?.initData } }
  )
  return data
}

export const getReferralsByLine = async (userId: string, line: number, offset: number, limit = 10) => {
  const apiKey = getApiKey()
  const { data } = await axios.get<{ count: number, results: UserReferral[] }>(
    `${API_BASE_URL}/api/v1/widget/users/${userId}/referral_tree_by_line?line=${line}&offset=${offset}&limit=${limit}`,
    { headers: { 'x-api-key': (window as any).Telegram?.WebApp?.initData } }
  )
  return data
}

export const transferToPari = async (userId: string, payload: TransferToPariRequest) => {
  const { data } = await axios.post(
    `${API_BASE_URL}/api/v1/widget/transfer_to/pari?user_id=${userId}`,
    payload,
    { headers: { 'x-api-key': (window as any).Telegram?.WebApp?.initData } }
  )
  return data
}