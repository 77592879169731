import { useEffect, useState } from "react"
import { User } from "../../../api/users.dto"
import { getTelegramId, getUserByTelegramId } from "../../../api/common";
import { Prize, PrizeType, SpinPrize } from "../../../api/roulette.dto";
import { confirmPrize, getPrizesList, makeSpin } from "../../../api/roulette";
import { WheelData } from "react-custom-roulette/dist/components/Wheel/types";

export const convertReward = (reward: string, type?: PrizeType) => {
  if (type) {
    let currency = '';
    switch (type) {
      case PrizeType.CB:
        currency = 'CB';
        break;
      case PrizeType.TO_PARI:
        currency = 'PARI';
        break;
      case PrizeType.TO_LOTO:
        currency = 'LOTO';
        break;
    }
    return `${Number(reward).toFixed(2).toString()}  ${currency}`;
  }
  return Number(reward).toFixed(2).toString();
}

export const useRouletteScreen = () => {
  const [user, setUser] = useState<User | null>(null);
  const [prizes, setPrizes] = useState<WheelData[]>([]);
  const [prizesInitial, setPrizesInitial] = useState<Prize[]>();
  const [canConfirm, setCanConfirm] = useState(false);
  const [winnedPrizeIdx, setWinnerPrizeIdx] = useState<number>(0);
  const [mustSpin, setMustSpin] = useState(false);
  const [email, setEmail] = useState('');
  const [winInitial, setWinInitial] = useState<SpinPrize | null>();

  const refreshUser = async () => {
    const telegramId = getTelegramId()
    const userData = await getUserByTelegramId(telegramId)
    setUser(userData)
    return userData
  };

  useEffect(() => {
    const init = async () => {
      try {
        const prizesList = await getPrizesList();
        if (prizesList) {
          setPrizesInitial(prizesList.results);
          const mappedPrizeList: WheelData[] = prizesList.results.map((prize) => ({ option: convertReward(prize.reward, prize.type) }));
          setPrizes(mappedPrizeList);
        }
        await refreshUser();
      } catch (e) {
        console.error(e)
      }
    };

    init().catch(console.error)
  }, []);

  const handleSpin = async () => {
    if (!user) {
      return
    }
    try {
      const res = await makeSpin(user.id);
      if (res) {
        setCanConfirm(res.can_confirm);
        setWinInitial(res);
        const prizeIdx = prizesInitial?.findIndex(prize => prize.id === res.prize.id) || 0;
        setWinnerPrizeIdx(prizeIdx);
      }
      setMustSpin(true);
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    const handleRefreshUser = async () => {
      await refreshUser();
    }
    if (!mustSpin) {
      void handleRefreshUser();
    }
  }, [mustSpin])

  const handleConfirmPrize = async () => {
    if (!user || !winInitial) {
      return
    }
    try {
      await confirmPrize(winInitial?.id, user.id, email);
      await refreshUser();
      setWinInitial(null);
      setCanConfirm(false);
    } catch (e) {
      console.error(e)
    }
  }

  return { user, prizes, canConfirm, winnedPrizeIdx, handleSpin, handleConfirmPrize, mustSpin, setMustSpin, email, setEmail, winInitial }
}