import { useFrensPage } from './frens.hooks'
import styles from './frens.module.scss'
import cn from 'classnames'
import { FrensGroup } from './frensGroup'

export default function Frens() {
  const { onClickReferralButton, totalFrens, level1Friends, level2Friends, level3Friends, level1Offset, level2Offset, level3Offset, setLevel1Offset, setLevel2Offset, setLevel3Offset, level1Total, level2Total, level3Total } = useFrensPage()

  return (
    <div className={styles.wrapper}>
      <div className={styles.cardWrap}>
        <div className={styles.card}>
          <h2 className={styles.title}>Invite friends. <br /> Earn points</h2>
          <p className={styles.worksTitle}>How it works</p>
          <ul className={styles.steps}>
            <li className={styles.item}>
              <p className={styles.itemTitle}>Share your invitation link</p>
              <p className={styles.itemText}>Spread the crypto among friends</p>
            </li>
            <li className={styles.item}>
              {/* TODO заменить название проекта */}
              <p className={styles.itemTitle}>Your friends join Cyber Base</p>
              <p className={styles.itemText}>And start farming points</p>
            </li>
            <li className={styles.item}>
              <p className={styles.itemTitle}>Earn 8% of the&nbsp;CB farmed by&nbsp;frens</p>
              <p className={styles.itemText}>Plus an&nbsp;extra 5% + 2% from their&nbsp;referrals</p>
            </li>
          </ul>
        </div>
        <div className={styles.card}>
          <span className={styles.friendName}>Total friends: {totalFrens}</span>          
        </div>
        <FrensGroup frens={level1Friends} level={1} total={level1Total} offset={level1Offset} setOffset={setLevel1Offset} />
        <FrensGroup frens={level2Friends} level={2} total={level2Total} offset={level2Offset} setOffset={setLevel2Offset} />
        <FrensGroup frens={level3Friends} level={3} total={level3Total} offset={level3Offset} setOffset={setLevel3Offset} />
      </div>
      <button className={styles.button} onClick={onClickReferralButton}>
        {/* TODO остаток рефералок */}
        Invite a friends
      </button>
    </div>
  )
}